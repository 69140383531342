import * as api from "./api";
import { getOrders, getOrderDocuments } from "./reducer";
import { getCurrentUser } from "@civicsource/users";

import { createAjaxAction } from "redux-rsi";

export function fetchOrders() {
	return createAjaxAction({ type: "ORDERS_FETCH" }, getState => {
		const state = getState();

		const { isLoading, isLoaded } = getOrders(state);
		if (isLoading || isLoaded) return;

		return api.getOrders(getCurrentUser(state));
	});
}

export function submitOrder(data) {
	return createAjaxAction({ type: "SUBMIT_ORDER", payload: data }, getState => {
		const state = getState();
		return api.submitOrder(data, getCurrentUser(state));
	});
}

export function fetchOrderDocuments(lotNumber) {
	return createAjaxAction(
		{
			type: "ORDER_DOCUMENTS_FETCH",
			payload: lotNumber
		},
		getState => {
			const state = getState();

			const { isLoading, isLoaded } = getOrderDocuments(state, lotNumber);
			if (isLoading || isLoaded) return;

			return api.getOrderDocuments(lotNumber, getCurrentUser(state));
		}
	);
}

export function uploadOrderDocuments(lotNumber, files) {
	return createAjaxAction(
		{
			type: "UPLOAD_ORDER_DOCUMENTS",
			payload: { lotNumber, files }
		},
		getState => {
			const state = getState();
			return api.uploadOrderDocuments(lotNumber, files, getCurrentUser(state));
		}
	);
}

export function updateOrderDocument(lotNumber, id, data) {
	return createAjaxAction(
		{
			type: "UPDATE_ORDER_DOCUMENT",
			payload: { lotNumber, id, data }
		},
		getState => {
			const state = getState();
			return api.updateOrderDocument(
				lotNumber,
				id,
				data,
				getCurrentUser(state)
			);
		}
	);
}
