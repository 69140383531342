import { createReducer } from "redux-rsi";
import Immutable from "seamless-immutable";
import { combineReducers } from "redux-seamless-immutable";

const orders = createReducer(
	Immutable({
		isLoading: false,
		isLoaded: false,
		isLoadError: false,
		items: []
	}),
	{
		onOrdersFetch(state) {
			return state.merge({
				isLoading: true,
				isLoadError: false
			});
		},

		onOrdersFetchCompleted(state, orders) {
			return state.merge({
				isLoading: false,
				isLoaded: true,
				items: orders
			});
		},

		onOrdersFetchFailed(state) {
			return state.merge({
				isLoading: false,
				isLoadError: true
			});
		},

		onSubmitOrderCompleted(state, order) {
			if (!state.isLoaded) return state;
			return state.set("items", [order, ...state.items]);
		}
	}
);

const newOrderStatus = createReducer(
	Immutable({
		isSubmitting: false,
		isSubmitError: false
	}),
	{
		onSubmitOrder(state) {
			return state.merge({
				isSubmitting: true,
				isSubmitError: false
			});
		},

		onSubmitOrderCompleted(state) {
			return state.merge({
				isSubmitting: false
			});
		},

		onSubmitOrderFailed(state) {
			return state.merge({
				isSubmitting: false,
				isSubmitError: true
			});
		}
	}
);

export default combineReducers({
	orders,
	newOrderStatus
});

export function getOrders(state) {
	return state.orders;
}

export function getNewOrderStatus(state) {
	return state.newOrderStatus;
}
