import order, * as fromOrder from "./order";
import orderDocument, * as fromOrderDocument from "./order-document";

import { registerReducer } from "redux-rsi";
import { combineReducers } from "redux-seamless-immutable";

const TREE_ROOT = "orders";

registerReducer(
	TREE_ROOT,
	combineReducers({
		order,
		orderDocument
	})
);

export function getOrders(state) {
	return fromOrder.getOrders(state[TREE_ROOT].order);
}

export function getNewOrderStatus(state) {
	return fromOrder.getNewOrderStatus(state[TREE_ROOT].order);
}

export function getOrderDocuments(state, lotNumber) {
	return fromOrderDocument.getOrderDocuments(
		state[TREE_ROOT].orderDocument,
		lotNumber
	);
}

export function getOrderDocument(state, id) {
	return fromOrderDocument.getOrderDocument(state[TREE_ROOT].orderDocument, id);
}
