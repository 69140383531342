import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "react-bootstrap";

import Orders from "../../orders";

const OrdersPage = props => (
	<>
		<Helmet>
			<title>Orders</title>
		</Helmet>

		<Grid style={{ paddingTop: 16, paddingBottom: 16 }}>
			<Orders {...props} />
		</Grid>
	</>
);

export default OrdersPage;
