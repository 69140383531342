import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { combineReducers } from "redux-seamless-immutable";
import { createSelector } from "reselect";

import { normalize as normalizeLotNumber } from "../../normalize";

import { uniqueId } from "lodash";

const documents = createReducer(Immutable({}), {
	onOrderDocumentsFetch(state, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: true,
				isLoadError: false
			},
			initDocs
		);
	},

	onOrderDocumentsFetchCompleted(state, docs, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: false,
				isLoaded: true,
				items: docs.map(d => d.id)
			},
			initDocs
		);
	},

	onOrderDocumentsFetchFailed(state, err, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: false,
				isLoadError: true
			},
			initDocs
		);
	},

	onUploadOrderDocumentsCompleted(state, docs, { lotNumber }) {
		lotNumber = normalizeLotNumber(lotNumber);
		let item = state[lotNumber];

		if (!item || !item.isLoaded) return state;

		item = item.set("items", [...docs.map(d => d.id), ...item.items]);
		return state.set(lotNumber, item);
	}
});

function initDocs() {
	return Immutable({
		isLoading: false,
		isLoaded: false,
		isLoadError: false,
		items: []
	});
}

const byId = createReducer(Immutable({}), {
	onOrderDocumentsFetchCompleted(state, docs) {
		return this.handleDocuments(state, docs);
	},

	onUploadOrderDocumentsCompleted(state, docs) {
		return this.handleDocuments(state, docs);
	},

	handleDocuments(state, docs) {
		docs.forEach(doc => {
			state = state.set(
				doc.id,
				Immutable(doc).merge({
					isUpdating: false,
					isUpdateError: false,
					isUploading: false
				})
			);
		});
		return state;
	},

	onUpdateOrderDocument(state, { id, data }) {
		let doc = state[id];
		if (!doc) return state;

		doc = doc.merge(data).merge({
			isUpdating: true,
			isUpdateError: false,
			_original: doc
		});

		return state.set(id, doc);
	},

	onUpdateOrderDocumentCompleted(state, serverData) {
		let doc = state[serverData.id];
		if (!doc) return state;

		doc = doc
			.merge(serverData)
			.set("isUpdating", false)
			.without("_original");

		return state.set(serverData.id, doc);
	},

	onUpdateOrderDocumentFailed(state, err, { id }) {
		let doc = state[id];
		if (!doc) return state;

		doc = doc._original.merge({
			isUpdating: false,
			isUpdateError: true
		});

		return state.set(id, doc);
	}
});

const newDocs = createReducer(Immutable({}), {
	onUploadOrderDocuments(state, { lotNumber, files }) {
		return state.set(
			normalizeLotNumber(lotNumber),
			files.map(f => ({
				id: uniqueId("uploading_"),
				name: f.name,
				contentType: f.type,
				isUploading: true
			}))
		);
	},

	onUploadOrderDocumentsCompleted(state, docs, { lotNumber }) {
		return state.without(normalizeLotNumber(lotNumber));
	},

	onUploadOrderDocumentsFailed(state, docs, { lotNumber }) {
		return state.without(normalizeLotNumber(lotNumber));
	}
});

export default combineReducers({
	documents,
	byId,
	newDocs
});

export const getOrderDocument = createSelector(
	(state, id) => state.byId[id],
	doc => doc.without("_original")
);

export const getOrderDocuments = createSelector(
	(state, lotNumber) => {
		lotNumber = normalizeLotNumber(lotNumber);
		return state.documents[normalizeLotNumber(lotNumber)] || initDocs();
	},
	(state, lotNumber) => {
		lotNumber = normalizeLotNumber(lotNumber);
		return state.newDocs[normalizeLotNumber(lotNumber)];
	},
	state => state,
	(results, newDocs, state) => {
		let items = results.items.map(docId => getOrderDocument(state, docId));

		if (newDocs) {
			items = [...newDocs, ...items];
		}

		return results.set("items", items);
	}
);
