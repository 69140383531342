import { checkStatus, parseJSON } from "fetch-helpers";

export function getOrders(auth) {
	return fetch(`/api/orders/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function submitOrder(data, auth) {
	return fetch(`/api/orders/`, {
		method: "POST",
		headers: populateHeaders(auth),
		body: JSON.stringify(data)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function getOrderDocuments(lotNumber, auth) {
	return fetch(`/api/orders/${lotNumber}/documents`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function uploadOrderDocuments(lotNumber, files, auth) {
	const body = new FormData();

	files.forEach((file, i) => {
		body.append(`file${i}`, file);
	});

	const headers = populateHeaders(auth);
	delete headers["Content-type"]; // let the browser set the content type

	const path = lotNumber ? `${lotNumber}/` : "";

	return fetch(`/api/orders/${path}documents/`, {
		method: "POST",
		headers,
		body
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function updateOrderDocument(lotNumber, id, data, auth) {
	return fetch(`/api/orders/${lotNumber}/documents/${id}`, {
		method: "PATCH",
		headers: populateHeaders(auth),
		body: JSON.stringify(data)
	})
		.then(checkStatus)
		.then(parseJSON);
}

function populateHeaders(auth) {
	let headers = {
		Accept: "application/json",
		"Content-type": "application/json"
	};

	if (auth) {
		headers.Authorization = `Bearer ${auth.token}`;
	}

	return headers;
}
