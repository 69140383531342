import { fetchOnUpdate } from "fetch-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchOrders } from "./actions";
import { getOrders } from "./reducer";

export default WrappedComponent => {
	const fetcher = fetchOnUpdate(({ fetchOrders }) => {
		fetchOrders();
	})(WrappedComponent);

	return connect(
		state => getOrders(state),
		dispatch => bindActionCreators({ fetchOrders }, dispatch)
	)(fetcher);
};
