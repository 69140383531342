import React from "react";
import { Helmet } from "react-helmet";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Button,
	withStyles
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { Link } from "gatsby";

import connect from "./connect";
import enforceLogin from "../login";

const Root = ({ items, classes }) => (
	<Paper className={classes.root}>
		<Helmet>
			<title>Recent Orders</title>
		</Helmet>

		<Typography variant="title">Recent Orders</Typography>

		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Order Number</TableCell>
					<TableCell>Location</TableCell>
					<TableCell>Account Number</TableCell>
					<TableCell>Status</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map(n => (
					<TableRow key={n.id}>
						<TableCell>
							<Link to={`/orders/${n.orderNumber}`}>{n.orderNumber}</Link>
						</TableCell>
						<TableCell>
							{n.countyFipsCode} {n.cityFipsCode}
						</TableCell>
						<TableCell>{n.accountNumber}</TableCell>
						<TableCell>{n.status}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>

		<div className={classes.actions}>
			<Link to="/orders/new">
				<Button
					variant="fab"
					color="primary"
					aria-label="new"
					title="New Order"
				>
					<Add />
				</Button>
			</Link>
		</div>
	</Paper>
);

const styles = theme => {
	return {
		"@global html": {
			fontSize: 16
		},
		root: {
			...theme.typography.body1,
			...theme.mixins.gutters({
				paddingTop: 16,
				paddingBottom: 16,
				marginTop: theme.spacing.unit * 3
			})
		},
		actions: {
			textAlign: "right"
		}
	};
};

export default enforceLogin(withStyles(styles)(connect(Root)));
